<template>
  <p>message: {{ message }}</p>
</template>

<script>
// 在这里导入模块，而不是在 `store/index.js` 中
import msgLazyModule from '@/store/lazy/msg.js'
export default {
  async asyncData ({ store, route }) {
    if (!store.hasModule('msg')) {
      store.registerModule('msg', msgLazyModule);
    }
    console.log('entry.server 触发 msg.vue 的 asyncData()');
    // 模拟数据获取
    await new Promise(resolve => setTimeout(resolve, 1000));
    store.commit('msg/setMessage', `msg.vue id=${route.query.id} set`);
  },
  computed: {
    // 从 store 的 state 对象中的获取 item。
    message () {
      return this.$store.state.msg ? this.$store.state.msg.message : 'Loading...';
    }
  },
  // 重要信息：当多次访问路由时，
  // 避免在客户端重复注册模块。
  destroyed () {
    this.$store.unregisterModule('msg')
  },
}
</script>
